import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { SEO } from '../components/seo';
import Layout from '../components/layout';
import SideBar from '../components/SideBar';
import {
  bodyRight,
  bodyLeft,
  indexBody,
  banner,
  dots,
  imageText,
} from '../components/layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

// Template for the index page, used because the content needs to float when on mobile

function IndexPage({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };
  return (
    <Layout meta={meta} pageTitle={meta.title}>
      <StaticImage
        className={banner}
        src="../assets/images/home-banner.jpg"
        alt="banner"
        loading="eager"
        quality={100}
        objectFit="cover"
        objectPosition="20% 25%"
        style={{ position: 'absolute', width: '100%' }}
      />
      <p className={imageText}>
        قال <span>المسيح</span>: أمّا أنا فجِئْتُ لِتكونَ لهُمُ{' '}
        <span>الحياةُ</span>، بل مِلءُ <span>الحياةِ</span>
      </p>
      {/* Body div which holds left and right content, managed by flex so it works on mobile */}
      <div className={indexBody}>
        <div className={bodyLeft}>
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
        <div className={bodyRight}>
          <SideBar />
        </div>
      </div>
      <StaticImage className={dots} src="../images/dots-light.png" alt="dots" />
    </Layout>
  );
}

export default IndexPage;

export const Head = ({ pageContext }) => <SEO pageContext={pageContext} />;
