import * as React from 'react';
import { Link } from 'gatsby';
import { dots } from '../components/layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

// Links and headers for right-side index content. Probably should move to mdx if possible

const SideBar = () => {
  return (
    <div>
      <StaticImage className={dots} src="../images/dots-light.png" alt="dots" />
      <blockquote>
        وجدت أهم شي بالإنجيل وهو شعور لايوصف. الشي الوحيد الذي يمكن أن يقال هو
        أنني أنصح الناس بقراءة الإنجيل حتى يعرفوا ماذا وجدت. اللسان يعجز عن وصف
        السيد المسيح ليس متكبر ولا مغرور. السيد المسيح أخذني إليه رغم اني مغرور
        ومتعجرف
        <h4>علي</h4>
      </blockquote>
      <blockquote>
        بعد قراءة الإنجيل شعرت بسلام عظيم لا يصدق ولم اشعر به من قبل. حتى اني
        ابتسم بلا سبب من السلام القلبي
        <h4>أم محمد</h4>
      </blockquote>
      <blockquote>
        أفهم من مناقشاتنا لماذا أتى المسيح إلى الأرض وأصبح جسداً، وفهمت لماذا
        أراد أن يخلصني من خطاياي
        <h4>محمد</h4>
      </blockquote>
      <blockquote>
        في عام 2017 قرأت الانجيل. بدأ كل من حولي ينتقدني ويحذرني مما سيحدث إذا
        صدقت وآمنت بالإنجيل. لذلك قررت أن أتوقف حتى يحين الوقت المناسب. ولم أكن
        أعرف ماذا يعني ذلك حينها. لكن عندما رأيت موقع سلام مع الله، أدركت أن
        الوقت الحالي هو الوقت المناسب للتعرف أكثر على إنجيل سيدي المسيح
        <h4>كمال</h4>
      </blockquote>
      <blockquote>
        كنت اعتقد أن الانجيل محرف ولكنني تفاجئت بعدما قرأته. مستحيل أن تكون
        كلمات الانجيل محرفة
        <h4>إسلام</h4>
      </blockquote>
      <Link to={'/mawadie/aisiilat-shayiea'}>اسئلة شائعة</Link>
      <Link to={'/mawadie/akthur-almawadie-qiraatan'}>اكثر المواضيع قراءة</Link>
    </div>
  );
};

export default SideBar;
